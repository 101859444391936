import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import axios, { AxiosRequestConfig } from 'axios';
import { forEach } from 'lodash';
import { AWSCredentialsAPI, LocalStorageCache } from '@eng-portal/czi-extensions-react';
import { operations, paths } from '@chanzuckerberg/happy-api';
import createClient from 'openapi-fetch';
import { OktaHappyApiTokenAPI } from './oktaHappyApiToken';
import {
  HappyAppConfigDeleteRequest,
  HappyAppConfigListRequest,
  HappyAppConfigResponse,
  HappyAppConfigSetRequest,
  HappyStackResponse,
  HappyStacksRequest,
} from '../types';

export const happyApiRef = createApiRef<HappyAPI>({ id: 'happy.api' });

export interface HappyAPI {
  getStacksForEnv(params: HappyStacksRequest): Promise<HappyStackResponse[]>

  setUseHappyConfigV2(useHappyConfigV2: boolean): void;
  getAppConfigs(query: operations['listAppConfig']['parameters']['query']): Promise<HappyAppConfigResponse[]>;
  setAppConfig(
    query: operations['setAppConfig']['parameters']['query'],
    body: operations['setAppConfig']['requestBody']['content']['application/json'],
  ): Promise<HappyAppConfigResponse>;

  deleteAppConfig(
    query: operations['deleteAppConfig']['parameters']['query'],
    key: operations['deleteAppConfig']['parameters']['path']['key'],
  ): Promise<HappyAppConfigResponse>;
}

type ClientV2 = ReturnType<typeof createClient<paths>>;

// Use this to make requests to the Happy API
export class HappyAPIClient implements HappyAPI {
  happyApiAuth: OktaHappyApiTokenAPI;
  awsAuth: AWSCredentialsAPI;
  configApi: ConfigApi;
  useHappyConfigV2: boolean;
  readonly urls: {
    BASE_URL: string
    STACKS_URL: string
    CONFIGS_URL: string
  };

  constructor(happyApiAuth: OktaHappyApiTokenAPI, awsAuth: AWSCredentialsAPI, configApi: ConfigApi) {
    this.happyApiAuth = happyApiAuth;
    this.awsAuth = awsAuth;
    this.configApi = configApi;
    this.useHappyConfigV2 = false;

    const baseUrl = configApi.getString('plugins.happy.happyApi.baseUrl');
    this.urls = {
      BASE_URL: baseUrl,
      STACKS_URL: `${baseUrl}/v1/stacks`,
      CONFIGS_URL: `${baseUrl}/v1/configs`,
    };
  }

  setUseHappyConfigV2(useHappyConfigV2: boolean): void {
    this.useHappyConfigV2 = useHappyConfigV2;
  }

  async getStacksForEnv(params: HappyStacksRequest): Promise<HappyStackResponse[]> {
    const key = `happy-stacks-${params.app_name}-${params.environment}`;
    const stacks = await LocalStorageCache.get<HappyStackResponse[]>(key, {
      ttlSeconds: 300, // 5 minutes
      fetchFn: async () => {
        const opts = await this.getRequestOpts({ params });
        const awsCredentialHeaders = await this.getAwsCredentialHeaders(params.aws_profile);
        forEach(awsCredentialHeaders, (value: string, header: string) => {
          opts.headers[header] = value;
        });

        try {
          const response = await axios.get<any, { data:{ records: HappyStackResponse[] } }>(this.urls.STACKS_URL, opts);
          return response.data.records;
        } catch (err: any) {
          if (err.response?.data) {
            const messages: string[] = err.response.data.map((item: any) => item.message);
            throw new Error(`Loading stacklists failed with the following errors: ${messages.join(', ')}`);
          }
          throw err;
        }
      },
    });

    return stacks;
  }

  async getAppConfigs(query: operations['listAppConfig']['parameters']['query']): Promise<HappyAppConfigResponse[]> {
    if (this.useHappyConfigV2) {
      return this.getAppConfigsV2(query);
    }

    const params: HappyAppConfigListRequest = {
      app_name: query.app_name,
      environment: query.environment,
    };
    if (query.stack?.length) {
      params.stack = query.stack;
    }
    return this.getAppConfigsV1(params);
  }

  private async getAppConfigsV1(params: HappyAppConfigListRequest): Promise<HappyAppConfigResponse[]> {
    const opts = await this.getRequestOpts({ params });
    const response = await axios.get(this.urls.CONFIGS_URL, opts);
    return response.data.records;
  }

  private async getAppConfigsV2(query: operations['listAppConfig']['parameters']['query']): Promise<HappyAppConfigResponse[]> {
    const header = await this.getV2Headers(query.aws_profile);
    const { data, error } = await this.getV2Client().GET('/app-configs', { params: { query, header } });

    if (error) {
      throw new Error(`Loading app configs failed with the following error: ${error}`);
    }
    return data;
  }

  async setAppConfig(
    query: operations['setAppConfig']['parameters']['query'],
    body: operations['setAppConfig']['requestBody']['content']['application/json'],
  ): Promise<HappyAppConfigResponse> {
    if (this.useHappyConfigV2) {
      return this.setAppConfigV2(query, body);
    }

    const request: HappyAppConfigSetRequest = {
      app_name: query.app_name,
      environment: query.environment,
      key: body.key,
      value: body.value,
    };
    if (query.stack?.length) {
      request.stack = query.stack;
    }
    return this.setAppConfigV1(request);
  }

  async setAppConfigV1(request: HappyAppConfigListRequest): Promise<any> {
    const opts = await this.getRequestOpts();
    const response = await axios.post(this.urls.CONFIGS_URL, request, opts);
    return response.data.record;
  }

  async setAppConfigV2(
    query: operations['setAppConfig']['parameters']['query'],
    body: operations['setAppConfig']['requestBody']['content']['application/json'],
  ): Promise<HappyAppConfigResponse> {
    const header = await this.getV2Headers(query.aws_profile);
    const { data, error } = await this.getV2Client().POST('/app-configs', { params: { query, header }, body });

    if (error) {
      throw new Error(`Setting app config failed with the following error: ${error}`);
    }
    return data;
  }

  async deleteAppConfig(
    query: operations['deleteAppConfig']['parameters']['query'],
    key: operations['deleteAppConfig']['parameters']['path']['key'],
  ): Promise<HappyAppConfigResponse> {
    if (this.useHappyConfigV2) {
      return this.deleteAppConfigV2(query, key);
    }

    const request: HappyAppConfigDeleteRequest = {
      app_name: query.app_name,
      environment: query.environment,
      key,
    };
    if (query.stack?.length) {
      request.stack = query.stack;
    }
    return this.deleteAppConfigV1(request);
  }

  async deleteAppConfigV1(request: HappyAppConfigDeleteRequest): Promise<any> {
    const opts = await this.getRequestOpts({ data: request });
    const response = await axios.delete(`${this.urls.CONFIGS_URL}/${request.key}`, opts);
    return response.data.record;
  }

  async deleteAppConfigV2(
    query: operations['deleteAppConfig']['parameters']['query'],
    key: operations['deleteAppConfig']['parameters']['path']['key'],
  ): Promise<HappyAppConfigResponse> {
    const header = await this.getV2Headers(query.aws_profile);
    const { data, error } = await this.getV2Client().DELETE('/app-configs/{key}', { params: { query, header, path: { key } } });

    if (error) {
      throw new Error(`Deleting app config failed with the following error: ${error}`);
    }
    return data;
  }

  async getRequestOpts(options: any = {}): Promise<AxiosRequestConfig & { headers: any }> {
    const token = await this.happyApiAuth.getTokenForUrl(this.urls.BASE_URL);
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    };
  }

  async getAwsCredentialHeaders(awsProfile: string): Promise<any> {
    const accountAlias = this.parseAwsProfile(awsProfile);
    const credentials = await this.awsAuth.getSTSCredential(accountAlias, ['okta-czi-admin', 'poweruser']);
    return {
      'x-aws-access-key-id': Buffer.from(credentials?.AccessKeyId!).toString('base64'),
      'x-aws-secret-access-key': Buffer.from(credentials?.SecretAccessKey!).toString('base64'),
      'x-aws-session-token': credentials?.SessionToken,
    };
  }

  parseAwsProfile(awsProfile: string): string {
    const matchResult = awsProfile.match(/^(.*)-(readyonly|okta-czi-admin|poweruser)$/);
    if (matchResult) {
      return matchResult[1];
    }
    return awsProfile;
  }

  getV2Client(): ClientV2 {
    return createClient<paths>({ baseUrl: `${this.urls.BASE_URL}/v2` });
  }

  async getV2Headers(awsProfile: string): Promise<operations['listAppConfig']['parameters']['header']> {
    const headers = await this.getAwsCredentialHeaders(awsProfile);
    const token = await this.happyApiAuth.getTokenForUrl(this.urls.BASE_URL);

    return { ...headers, Authorization: `Bearer ${token}` };
  }
}
