import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { OktaTokenApi } from '@eng-portal/czi-extensions-react';
import { components, operations, paths } from '@chanzuckerberg/czi-idp-api';
import createClient from 'openapi-fetch';

export const privilegeEscalationApiRef = createApiRef<PrivilegeEscalationAPI>({ id: 'priv-esc.api' });

export type ListRequestResponse =
  operations['get_requests_by_user_id_v1_users__sub__pending_requests_by_sub_get']['responses']['200']['content']['application/json'];
export type CreateRequestResponse =
  operations['create_request_v1_requests_post']['responses']['200']['content']['application/json'];
export type ListStakeholderResponses = operations['get_stakeholder_responses_v1_users__sub__stakeholder_responses_get']['responses'];
export type ListStakeholderSuccessResponses = ListStakeholderResponses['200']['content']['application/json'];
export type StakeholderResponse = ListStakeholderSuccessResponses[0];

export type StakeholderResponseApproverResponse =
  operations['get_stakeholder_response_approver_v1_stakeholder_responses__id__approver_get']['responses'];
export type StakeholderResponseApproverSuccessResponse = StakeholderResponseApproverResponse['200']['content']['application/json'];

export type PrivilegeEscalationRequest = components['schemas']['RequestResponse'];
export type StakeholderResponseVerdict = components['schemas']['Status'];
export type RequestRequestorRead = components['schemas']['SupplimentedIdentity'];
export type ListPluginsResponse = string[];

export type Review = {
  stakeholderResponse: StakeholderResponse
  request: PrivilegeEscalationRequest
  requestor: RequestRequestorRead
};

export type FormFields = components['schemas']['FormField'];
export interface PrivilegeEscalationAPI {
  listRequests(): Promise<ListRequestResponse>;
  createRequest(
    pepType: string, // TODO use python enum here or use dynamic type from API
    pluginData: Record<string, string>,
    duration: number,
    reason: string,
    jiraTicketUrl: string,
    targetEmail: string,
    targetSub: string,
  ): Promise<CreateRequestResponse>;
  listStakeholderResponsesForRequest(requestId: number): Promise<ListStakeholderSuccessResponses>;
  getStakeholderResponseApprover(responseId: number): Promise<StakeholderResponseApproverSuccessResponse>;
  getRequestorForRequest(requestId: number): Promise<RequestRequestorRead>
  listReviewsForCurrentUser(): Promise<ListStakeholderSuccessResponses>;
  patchStakeholderResponse(responseId: number, verdict: StakeholderResponseVerdict, reason: string): Promise<StakeholderResponse>;
  getRequestForStakeholderResponse(responseId: number): Promise<PrivilegeEscalationRequest>;
  getRequest(requestId: number): Promise<PrivilegeEscalationRequest>;
  listPlugins(): Promise<ListPluginsResponse>;
  getPluginFields(requestType: string): Promise<FormFields>;
  revokeRequest(requestId: number): Promise<void>;
  getToken(): Promise<string>;
}

export class PrivilegeEscalationClient implements PrivilegeEscalationAPI {
  private readonly config: ConfigApi;
  private readonly client: ReturnType<typeof createClient<paths>>;

  constructor(
    private readonly oktaTokenApi: OktaTokenApi,
    configApi: ConfigApi,
  ) {
    this.config = configApi.getConfig('plugins.privilegeEscalation');
    const baseUrl = this.config.getString('baseUrl');

    this.client = createClient<paths>({ baseUrl });
  }

  async getPluginFields(requestType: string): Promise<FormFields> {
    const token = await this.getToken();

    const header = await this.getHeaders(token);
    const { data, error } = await this.client.GET('/v1/plugins/{plugin_type}/fields', {
      params: {
        path: {
          plugin_type: requestType,
        },
        header,
      },
    });
    if (error) {
      throw new Error(`listing plugins request failed with the following error: ${JSON.stringify(error)}`);
    }
    return data;
  }

  async listPlugins(): Promise<ListPluginsResponse> {
    const token = await this.getToken();

    const header = await this.getHeaders(token);
    const { data, error } = await this.client.GET('/v1/plugins', { params: { header } });
    if (error) {
      throw new Error(`listing plugins request failed with the following error: ${JSON.stringify(error)}`);
    }
    return data;
  }

  async getRequest(requestId: number): Promise<PrivilegeEscalationRequest> {
    const token = await this.getToken();

    const header = await this.getHeaders(token);
    const { data, error } = await this.client.GET('/v1/requests/{id}', { params: { path: { id: requestId }, header } });
    if (error) {
      throw new Error(`getting priv esc request failed with the following error: ${JSON.stringify(error)}`);
    }
    return data;
  }

  async listRequests(): Promise<ListRequestResponse> {
    const token = await this.getToken();
    const jwtPayload = JSON.parse(atob(token.split('.')[1]));
    const { sub } = jwtPayload;

    const header = await this.getHeaders(token);
    const { data, error } = await this.client.GET('/v1/users/{sub}/pending-requests-by-sub', { params: { path: { sub }, header } });
    if (error) {
      throw new Error(`listing priv esc requests failed with the following error: ${JSON.stringify(error)}`);
    }
    return data;
  }

  async createRequest(
    pepType: string,
    pluginData: Record<string, string>,
    duration: number,
    reason: string,
    jiraTicketUrl: string,
    targetEmail: string,
    targetSub: string,
  ): Promise<CreateRequestResponse> {
    const body: operations['create_request_v1_requests_post']['requestBody']['content']['application/json'] = {
      pep_type: pepType,
      plugin_data: pluginData,
      duration,
      reason,
      jira_ticket: jiraTicketUrl,
      target_email: targetEmail,
      target_sub: targetSub,
    };

    const header = await this.getHeaders();
    const { data, error } = await this.client.POST('/v1/requests', { params: { header }, body });

    if (error) {
      throw new Error(`creating priv esc request failed with the following error: ${error}`);
    }
    return data;
  }

  async listStakeholderResponsesForRequest(requestId: number): Promise<ListStakeholderSuccessResponses> {
    const header = await this.getHeaders();
    const { data, error } = await this.client.GET(
      '/v1/requests/{request_id}/responses',
      { params: { path: { request_id: requestId }, header } },
    );
    if (error) {
      throw new Error(`listing stakeholder responses for request ${requestId} failed with the following error: ${error}`);
    }
    return data;
  }

  async getStakeholderResponseApprover(responseId: number): Promise<StakeholderResponseApproverSuccessResponse> {
    const header = await this.getHeaders();
    const { data, error } = await this.client.GET(
      '/v1/stakeholder-responses/{id}/approver',
      { params: { path: { id: responseId }, header } },
    );
    if (error) {
      throw new Error(`getting stakeholder response approver for response ${responseId} failed with the following error: ${error}`);
    }
    return data;
  }

  async listReviewsForCurrentUser(): Promise<ListStakeholderSuccessResponses> {
    const token = await this.getToken();
    const jwtPayload = JSON.parse(atob(token.split('.')[1]));
    const { sub } = jwtPayload;

    const header = await this.getHeaders(token);
    const { data, error } = await this.client.GET(
      '/v1/users/{sub}/stakeholder-responses',
      { params: { path: { sub }, header } },
    );
    if (error) {
      throw new Error(`listing pending requests for current user failed with the following error: ${error}`);
    }

    return data;
  }

  async patchStakeholderResponse(responseId: number, verdict: StakeholderResponseVerdict, reason: string): Promise<StakeholderResponse> {
    const header = await this.getHeaders();
    type BodyType = operations['update_stakeholder_responses_v1_stakeholder_responses__id__patch']['requestBody'];
    type JsonBodyType = BodyType['content']['application/json'];
    const body: JsonBodyType = {
      verdict,
      reason,
    };
    const { data, error } = await this.client.PATCH(
      '/v1/stakeholder-responses/{id}',
      { params: { path: { id: responseId }, header }, body },
    );
    if (error) {
      throw new Error(`patching stakeholder response ${responseId} failed with the following error: ${error}`);
    }
    return data;
  }

  async getRequestForStakeholderResponse(responseId: number): Promise<PrivilegeEscalationRequest> {
    const header = await this.getHeaders();
    const { data, error } = await this.client.GET(
      '/v1/stakeholder-responses/{response_id}/request',
      { params: { path: { response_id: responseId }, header } },
    );
    if (error) {
      throw new Error(`getting aws request for stakeholder response ${responseId} failed with the following error: ${error}`);
    }
    return data;
  }

  async getRequestorForRequest(requestId: number): Promise<RequestRequestorRead> {
    const header = await this.getHeaders();
    const { data, error } = await this.client.GET(
      '/v1/requests/{id}/requestor',
      { params: { path: { id: requestId }, header } },
    );
    if (error) {
      throw new Error(`getting requestor for aws request ${requestId} failed with the following error: ${error}`);
    }
    return data;
  }

  async revokeRequest(requestId: number): Promise<void> {
    const header = await this.getHeaders();
    const { error } = await this.client.DELETE('/v1/requests/{id}', { params: { path: { id: requestId }, header } });
    if (error) {
      throw new Error(`revoking request ${requestId} failed with the following error: ${error}`);
    }
  }

  async getToken(): Promise<string> {
    const clientId = this.config.getString('oktaClientId');
    const issuerUrl = this.config.getOptionalString('oktaIssuerUrl');
    return this.oktaTokenApi.getToken(clientId, issuerUrl);
  }

  async getHeaders(token?: string): Promise<{ authorization: string }> {
    const tkn = token || await this.getToken();
    return { authorization: `Bearer ${tkn}` };
  }
}
