import { createPermission, BasicPermission } from '@backstage/plugin-permission-common';
import { Config } from '@backstage/config';
import { includesAdminGroup, isMemberOfGroup } from './identityHelper';

type PermissionConfigItem = {
  permission: BasicPermission;
  canAccess: (currentUserGroups: string[], { config }: { config: Config }) => boolean;
};

export const keyManagementRootViewPermission = createPermission({
  name: 'aws-key-management.root',
  attributes: { action: 'read' },
});

export const PermissionConfig: Record<string, PermissionConfigItem> = {
  keyManagementRootView: {
    permission: keyManagementRootViewPermission,
    canAccess: (currentUserGroups, { config }) => {
      const keyManagementGroups = config.getOptionalStringArray('permission.keyManagementAllowedGroups') || [];
      return includesAdminGroup(currentUserGroups, { config }) || isMemberOfGroup(currentUserGroups, keyManagementGroups);
    },
  },
};
