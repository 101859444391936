import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  FormHelperText, TextField, Grid, Typography,
} from '@material-ui/core';
import { Select, SelectItem, SelectedItems } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import { Entity, EntityRelation } from '@backstage/catalog-model';
import { OktaTeamEntity } from '@eng-portal/czi-extensions-common';
import { SelectAutocomplete, SelectAutocompleteOption } from '@eng-portal/czi-extensions-react';

type OktaTeamProps = {
  onChange: any
  formData: any
};

const labelDescription = {
  newTeamName: 'The new name of Okta team',
  organization: 'The organization that the new team belongs to',
  newTeamDefinition: 'The Github file that the new team would be store',
  parentTeam: 'A set of parent teams. Members matched by any rules in the current okta team will also be added to parent teams.',
  authorizedUsers: 'A set of users that are authorized for this team.',
};

/**
 * This is based on the variable list from terraform.
 * We would like to add support for builder_expressions, authorized_aws_roles, etc
 * in the future.
 * @param props formData will be passed from OktaAppPickerExtensions
 * @returns {JSX.Element}
 */
export function CreateNewOktaTeamForm(props: OktaTeamProps): JSX.Element {
  const {
    onChange, formData,
  } = props;
  const [newTeamName, setNewTeamName] = useState<string>('');
  const [domain, setDomain] = useState<string>('');
  const [parentTeams, setParentTeams] = useState<SelectAutocompleteOption[]>();
  const [parentTeamList, setParentTeamList] = useState<SelectAutocompleteOption[]>([]);
  const [teamDefinitionList, setTeamDefinitionList] = useState<SelectItem[]>([]);
  const [definition, setDefinition] = useState<string>('');

  const catalogApi = useApi(catalogApiRef);

  const { value: users } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'User',
      },
    });
    return items
      .map((entity) => ({ label: entity.metadata.title, value: entity.metadata.name } as SelectAutocompleteOption))
      .filter((item) => item.label != null);
  });

  const { value: domainVal } = useAsync(async () => {
    const owners = ['team-czi-edu', 'team-czi-sci', 'team-central-infra-eng-core'];
    const dict = {
      'team-czi-edu': 'Education',
      'team-czi-sci': 'Science',
      'team-central-infra-eng-core': 'Central Tech',
    };
    const domainList: SelectAutocompleteOption[] = [];
    const teamsInDomainMap: Record<string, SelectAutocompleteOption[]> = {};
    const teamsDefInDomainMap: Record<string, SelectAutocompleteOption[]> = {};

    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'Group',
      },
    });
    const domains = items.filter((group) => owners.includes(String(group.metadata.name)));
    domains.forEach((group) => domainList.push({
      label: dict[group.metadata.name as keyof typeof dict],
      value: dict[group.metadata.name as keyof typeof dict],
    }));
    if (domains) {
      domains.map(async (item: Entity) => {
        const domainName = dict[item.metadata.name as keyof typeof dict];
        const { relations } = item;
        if (relations && relations.length > 0) {
          const relationEntities = relations.filter((relation) => relation.type === 'parentOf');
          relationEntities.forEach(async (relationEntity: EntityRelation) => {
            const { targetRef } = relationEntity;
            const result = await catalogApi.getEntities({
              filter: {
                kind: 'Group',
                'metadata.name': targetRef.split('/')[1],
              },
            });
            const team = result.items[0] as OktaTeamEntity;
            if (!teamsInDomainMap[domainName]) {
              teamsInDomainMap[domainName] = [];
            }
            if (!teamsDefInDomainMap[domainName]) {
              teamsDefInDomainMap[domainName] = [];
            }
            teamsInDomainMap[domainName].push({
              label: team.metadata.name,
              value: team.metadata.name,
            });
            teamsDefInDomainMap[domainName].push({
              label: team.spec.definition.path,
              value: team.spec.definition.path,
            });
          });
        }
      });
    }
    return {
      teamsInDomainMap, teamsDefInDomainMap, domainList,
    };
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          required
          id="new-team-name"
          label="New Team Name"
          value={newTeamName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const teamName = event.target.value;
            setNewTeamName(teamName);
            onChange(
              { ...formData, team: teamName },
            );
          }}
          variant="standard"
        />
        <FormHelperText id="new-team-name">{labelDescription.newTeamName}</FormHelperText>
      </Grid>
      <Grid item>
        <Select
          placeholder="Select Organization"
          label="Organization"
          selected={domain}
          items={domainVal?.domainList ?? []}
          onChange={(selected: SelectedItems): void => {
            const domainName = String(selected);
            setDomain(domainName);
            if (domainVal) {
              const {
                teamsInDomainMap, teamsDefInDomainMap,
              } = domainVal;
              setParentTeamList(teamsInDomainMap[domainName]);
              setTeamDefinitionList(teamsDefInDomainMap[domainName]);
            }
            onChange({ ...formData, organization: domainName });
          }}
        />
        <FormHelperText id="organization">{labelDescription.organization}</FormHelperText>
      </Grid>
      <Grid item>
        <Select
          placeholder="Select New Team Definition"
          label="New Team Definition"
          selected={definition}
          items={teamDefinitionList ?? []}
          onChange={(selected: SelectedItems): void => {
            setDefinition(String(selected));
            onChange({ ...formData, definition: String(selected) });
          }}
        />
        <FormHelperText id="new-team-definition">{labelDescription.newTeamDefinition}</FormHelperText>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" style={{ paddingBottom: '10px' }}><strong>Parent Teams</strong></Typography>
        <SelectAutocomplete
          label="Select Parent Teams"
          selectedItems={parentTeams || []}
          items={parentTeamList || []}
          setSelectedItems={(selected: SelectAutocompleteOption[]): void => {
            const newParentTeams = selected.map((team) => String(team.value));
            setParentTeams(selected);
            onChange({ ...formData, parentTeams: newParentTeams });
          }}
          sortItems
          multiple
          allowSelectAll={false}
        />
        <FormHelperText id="parent-team">{labelDescription.parentTeam}</FormHelperText>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" style={{ paddingBottom: '10px' }}><strong>Authorized Users</strong></Typography>
        <SelectAutocomplete
          label="Select Users"
          selectedItems={formData.authorizedUsers}
          items={users || []}
          setSelectedItems={(selected: SelectAutocompleteOption[]): void => {
            const authorizedUsers = selected.map((user) => String(user.value));
            onChange({ ...formData, authorizedUsers });
          }}
          sortItems
          multiple
          allowSelectAll={false}
        />
        <FormHelperText id="authorized-users">{labelDescription.authorizedUsers}</FormHelperText>
      </Grid>
    </Grid>
  );
}
