import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  githubAuthApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import {
  OktaToken, oktaTokenApiRef, AWSCredentials, awsCredentialsApiRef,
} from '@eng-portal/czi-extensions-react';
import { rootRouteRef } from './routes';
import { HappyConfig, happyConfigApiRef } from './apis/happyConfig';
import { HappyAPIClient, happyApiRef } from './apis/happyApi';
import { OktaHappyApiToken, oktaHappyApiTokenApiRef } from './apis/oktaHappyApiToken';
import { CZI_ANNOTATION_HAPPY_APP } from './constants';
import { HappyBackend, happyBackendApiRef } from './apis/happyBackend';

export const isHappyApp = (entity: Entity): boolean => Boolean(entity?.metadata.annotations?.[CZI_ANNOTATION_HAPPY_APP]);

export const happyPlugin = createPlugin({
  id: 'happy',
  apis: [
    createApiFactory({
      api: happyConfigApiRef,
      deps: { gitAuthApi: githubAuthApiRef },
      factory: ({ gitAuthApi }) => new HappyConfig(gitAuthApi),
    }),
    OktaToken.createDefaultApiFactory(),
    createApiFactory({
      api: oktaHappyApiTokenApiRef,
      deps: { oktaTokenApi: oktaTokenApiRef, configApi: configApiRef },
      factory: ({ oktaTokenApi, configApi }) => new OktaHappyApiToken(oktaTokenApi, configApi),
    }),
    AWSCredentials.createDefaultApiFactory(),
    createApiFactory({
      api: happyApiRef,
      deps: { happyApiAuth: oktaHappyApiTokenApiRef, awsAuth: awsCredentialsApiRef, configApi: configApiRef },
      factory: ({ happyApiAuth, awsAuth, configApi }) => new HappyAPIClient(happyApiAuth, awsAuth, configApi),
    }),
    createApiFactory({
      api: happyBackendApiRef,
      deps: { identityApi: identityApiRef, configApi: configApiRef },
      factory: ({ identityApi, configApi }) => new HappyBackend(identityApi, configApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const HappyPage = happyPlugin.provide(
  createRoutableExtension({
    name: 'HappyPage',
    component: () => import('./components/HappyRoot').then((m) => m.HappyRoot),
    mountPoint: rootRouteRef,
  }),
);
