import React from 'react';
import { GroupEntity } from '@backstage/catalog-model';
import {
  useEntity, catalogApiRef,
} from '@backstage/plugin-catalog-react';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
  Link,
  Table,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { generatePath } from 'react-router-dom';
import { JsonObject } from '@backstage/types';
import { OktaApplicationEntity } from '@eng-portal/czi-extensions-common';
import _ from 'lodash';

function GroupComponent(prop: { group: GroupEntity }): JSX.Element {
  const { group } = prop;
  const { metadata: { name } } = group;
  const groupLink = generatePath(`/catalog/default/group/${name}`);
  return (
    <Link to={groupLink}>{ name }</Link>
  );
}
function RenderingComponent(
  prop: { key: string, val: JsonObject | string | string[], groupsInApp: GroupEntity[] | undefined },
): JSX.Element {
  const { key, val, groupsInApp } = prop;
  if (key === 'groups') {
    return (
      <Grid container spacing={0}>
        {groupsInApp && groupsInApp.map((group) => (
          <Grid item xs={12}>
            <GroupComponent group={group} key={group.metadata.uid} />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <Typography>{JSON.stringify(val)}</Typography>
  );
}

export function OktaAppProfileCard(): JSX.Element {
  const { entity: applicationEntity } = useEntity<OktaApplicationEntity>();
  const { spec } = applicationEntity;
  const { groups } = spec;
  const catalogApi = useApi(catalogApiRef);
  const {
    loading,
    error,
    value: groupsInApp,
  } = useAsync(async () => {
    const groupsList = await catalogApi.getEntities({
      filter: {
        kind: 'Group',
      },
    });
    const filtered = groupsList.items.filter((group) => groups.includes(String(group.metadata.id)));
    return filtered as GroupEntity[];
  }, [catalogApi, applicationEntity]);

  if (loading) {
    return <Progress />;
  } if (error) {
    return <ResponseErrorPanel error={error} />;
  }
  const columns = [
    {
      title: 'Key',
      field: 'key',
      render: (row: any) => <Typography variant="h6">{row.key}</Typography>,
    },
    {
      title: 'Value',
      field: 'value',
      render: (row: any) => RenderingComponent({ key: row.key, val: row.value, groupsInApp }),
    },
  ];

  const data = Object.keys(_.omit(spec, ['type', 'owner', 'settings', 'lifecycle', 'credentials'])).map((key) => {
    const value = spec[key as keyof typeof spec];
    return {
      key,
      value,
    };
  });
  return (
    <InfoCard title="Metadata">
      <Table
        options={{
          paging: false, search: false, header: false, toolbar: false,
        }}
        columns={columns}
        data={data}
      />
    </InfoCard>
  );
}
