import { createPlugin, createRoutableExtension, createApiFactory } from '@backstage/core-plugin-api';
import { awsCredentialsApiRef } from '@eng-portal/czi-extensions-react';
import { rootRouteRef } from './routes';
import { AWSCostExplorerClient, awsCostExplorerApiRef } from './apis/costExplorerApi';

export const awsCostExplorerPlugin = createPlugin({
  id: 'aws-cost-explorer',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: awsCostExplorerApiRef,
      deps: { awsCredentialsApi: awsCredentialsApiRef },
      factory: ({ awsCredentialsApi }) => new AWSCostExplorerClient(awsCredentialsApi, 'us-west-2'),
    }),
  ],
});

export const AwsCostExplorerPage = awsCostExplorerPlugin.provide(
  createRoutableExtension({
    name: 'AwsCostExplorerPage',
    component: () => import('./components/CostExplorer').then((m) => m.CostExplorerComponent),
    mountPoint: rootRouteRef,
  }),
);
