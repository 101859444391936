import React, { useEffect, useState } from 'react';
import { useSidebarOpenState } from '@backstage/core-components';
import {
  Grid, SvgIcon, makeStyles,
} from '@material-ui/core';
import { Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { CziLogoSvg } from '@eng-portal/czi-extensions-react';

const useSidebarLogoStyles = makeStyles({
  link: {
    width: 'auto',
    textDecoration: 'none',
    color: '#ffffff',
  },
  linkWrapper: {
    padding: 9,
  },
  linkText: {
    fontSize: '1.8em',
    margin: 0,
  },
  linkSubtext: {
    marginTop: 2,
    color: '#cccccc',
  },
});

function LogoIcon(): JSX.Element {
  return (
    <SvgIcon htmlColor="#FF414B" style={{ height: 60, width: 60 }}>
      <CziLogoSvg />
    </SvgIcon>
  );
}

function LogoText(): JSX.Element {
  const classes = useSidebarLogoStyles();
  return (
    <div>
      <h1 className={classes.linkText}>eng-portal</h1>
      <p className={classes.linkSubtext}>Powered by Backstage</p>
    </div>
  );
}

function LogoFull(props: { showLogoText: boolean }): JSX.Element {
  const { showLogoText } = props;

  return (
    <Grid container justifyContent="center" direction="row" spacing={1}>
      <Grid item style={{ paddingTop: '5px', paddingBottom: '10px' }}>
        <LogoIcon />
      </Grid>
      { showLogoText && (
      <Grid item>
        <LogoText />
      </Grid>
      )}
    </Grid>
  );
}

export function SidebarLogo(): JSX.Element {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  const [showLogoText, setShowLogoText] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setShowLogoText(isOpen), 200);
    } else {
      setShowLogoText(isOpen);
    }
  }, [isOpen, showLogoText]);

  return (
    <div className={classes.linkWrapper}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        <LogoFull showLogoText={showLogoText} />
      </Link>
    </div>
  );
}
