import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { FieldValidation } from '@rjsf/utils';
import {
  FormControl, FormHelperText, Grid, Switch, FormControlLabel,
} from '@material-ui/core';
import { Select, SelectItem, SelectedItems } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import { CustomFieldExtensionSchema, FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { OktaApplicationEntity, OktaTeamEntity } from '@eng-portal/czi-extensions-common';
import { has } from 'lodash';
import { CreateNewOktaTeamForm } from './CreateNewOktaTeamForm';

type OktaAppPickerFormData = {
  application: string
  team: string
  organization?: string
  isCustomTeam: boolean
  definition: string
  parentTeams?: string[]
  authorizedUsers?: string[]
};

export function OktaAppPicker({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<OktaAppPickerFormData>): JSX.Element {
  const [app, setApp] = useState('');
  const [team, setTeam] = useState('');
  const [isCustomTeam, setIsCustomTeam] = useState<boolean>(false);
  const [oktaApps, setOktaApps] = useState<SelectItem[]>([]);
  const [oktaTeams, setOktaTeams] = useState<SelectItem[]>([{ label: 'none', value: 'none' }]);
  const catalogApi = useApi(catalogApiRef);

  const { value: teamVal } = useAsync(async () => {
    const dict: Record<string, string[]> = {};
    const defDict: Record<string, {
      id: string,
      name: string,
      path: string,
    }> = {};
    const { items } = await catalogApi.getEntities({
      filter: {
        kind: 'Component',
        'spec.type': 'Application',
      },
    });
    const oktaAppList = items.map((item) => {
      const { title, name } = item.metadata;
      return { label: title ?? name, value: name };
    }).sort((a, b) => a.label.localeCompare(b.label));

    const { items: allGroups } = await catalogApi.getEntities({
      filter: {
        kind: 'Group',
      },
    });

    items.forEach(async (oktaApp) => {
      const appEntity: OktaApplicationEntity = oktaApp as OktaApplicationEntity;
      const appName = oktaApp.metadata.name;
      const groupIds: string[] = appEntity.spec.groups;
      dict[appName] = groupIds;
    });

    allGroups.forEach((group) => {
      const oktaGroup = group as OktaTeamEntity;
      const groupId = String(oktaGroup.metadata.id);
      const { path } = oktaGroup.spec.definition;
      defDict[groupId] = { id: groupId, name: group.metadata.name, path };
    });
    setOktaApps(oktaAppList);
    return { teamAssignedToAppMap: dict, teamDefinition: defDict };
  });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0 && !formData}
        >
          <Select
            placeholder="Select Okta Application"
            label="Okta Application"
            selected={app}
            items={oktaApps}
            onChange={(selected: SelectedItems): void => {
              const application = String(selected);
              setApp(application);
              if (formData) {
                onChange({ ...formData, application: `component:default/${application}` });
              }
              if (teamVal) {
                const groupIds = teamVal.teamAssignedToAppMap[application];
                const teams: SelectItem[] = [];
                groupIds.forEach((groupId) => {
                  if (teamVal.teamDefinition[groupId]) {
                    teams.push({
                      label: teamVal.teamDefinition[groupId].name,
                      value: groupId,
                    });
                  }
                });
                setOktaTeams(teams);
              }
            }}
          />
          <FormHelperText id="appName">The Okta app that the user needs access to</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        { !isCustomTeam ? (
          <FormControl
            margin="normal"
            required={required}
            error={rawErrors?.length > 0 && !formData}
          >
            <Select
              placeholder="Select Okta Team"
              label="Okta Team"
              items={oktaTeams}
              selected={team}
              onChange={(selected: SelectedItems): void => {
                const selectedTeam = String(selected);
                const name = teamVal ? teamVal.teamDefinition[selectedTeam].name : '';
                const path = teamVal ? teamVal.teamDefinition[selectedTeam].path : '';
                if (formData) {
                  onChange({ ...formData, team: `group:default/${name}`, definition: path });
                }
                setTeam(selectedTeam);
              }}
            />
            <FormHelperText id="teamName">The team that should be granted access to</FormHelperText>
          </FormControl>
        ) : null}

      </Grid>
      <Grid item>
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0 && !formData}
        >
          <FormControlLabel
            control={(
              <Switch
                size="medium"
                color="primary"
                onChange={(): void => {
                  if (formData) {
                    onChange({
                      ...formData, isCustomTeam: !isCustomTeam, definition: '', team: '',
                    });
                  }
                  setTeam('');
                  setIsCustomTeam(!isCustomTeam);
                }}
              />
          )}
            label="Create New Team"
          />
        </FormControl>
      </Grid>
      <Grid item>
        { isCustomTeam ? (
          <CreateNewOktaTeamForm onChange={onChange} formData={formData} />
        ) : null}
      </Grid>
    </Grid>
  );
}

export const validateOktaAppPickerFormDataValidation = (
  formData: OktaAppPickerFormData,
  validation: FieldValidation,
): void => {
  const requiredField = ['application', 'team', 'definition'];
  requiredField.forEach((field) => {
    if (!has(formData, field)) {
      validation.addError(`Invalid input: ${field} not provided.`);
    }
  });
  if (has(formData, 'isCustomTeam') && !formData.organization) {
    if (!formData.organization) {
      validation.addError('Invalid input: Organization not provided for custom new team.');
    }
  }
  const { team } = formData;
  if (team === '*') {
    validation.addError(
      'Invalid input: Custom team name cannot be empty.',
    );
  }
};

export const OktaAppPickerSchema: CustomFieldExtensionSchema = {
  uiOptions: {
    type: 'object',
    properties: {
      application: {
        $id: '#/properties/application',
        type: 'string',
        title: 'The Okta Application Schema',
        examples: ['Snowflake'],
      },
      team: {
        $id: '#/properties/team',
        type: 'string',
        title: 'The Okta Team Schema',
        default: '',
        examples: ['team-czi-test'],
      },
      organization: {
        $id: '#/properties/organization',
        type: 'string',
        title: 'The Okta Organization Schema',
        default: 'central',
        examples: ['science', 'education', 'central'],
      },
      definition: {
        $id: '#/properties/definition',
        type: 'string',
        title: 'The team Definition in Github Schema',
        default: 'central',
        examples: ['test.tf'],
      },
      parentTeams: {
        $id: '#/properties/parentTeams',
        type: 'array',
        items: {
          type: 'string',
        },
        title: 'The Parent Teams Schema',
        default: [],
        examples: ['team-czi-parent'],
      },
      authorizedUsers: {
        $id: '#/properties/authorizedUsers',
        type: 'array',
        items: {
          type: 'string',
        },
        title: 'The Authorized Users Schema',
        default: [],
        examples: ['user1', 'user2'],
      },
    },
    required: ['application', 'team'],
  },
  returnValue: {
    type: 'object',
    properties: {
      application: { type: 'string' },
      team: { type: 'string' },
      organization: { type: 'string' },
      definition: { type: 'string' },
      parentTeams: { type: 'array' },
      authorizedUsers: { type: 'array' },
    },
    if: {
      properties: {
        team: { const: '*' },
      },
      required: ['application', 'team', 'organization', 'definition'],
    },
    then: { required: ['application', 'team', 'definition'] },
  },
};
