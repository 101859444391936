import { HomePageRandomJoke, HomePageStarredEntities } from '@backstage/plugin-home';
import { Grid } from '@material-ui/core';
import React from 'react';
import { HappyAppsTile } from '@eng-portal/happy';

export function HomePageTiles(): JSX.Element {
  return (
    <Grid container item xs={12} spacing={3}>
      <HappyAppsTile xs={12} md={6} />
      <Grid item xs={12} md={6}>
        <HomePageRandomJoke />
      </Grid>
      <Grid item xs={12} md={6}>
        <HomePageStarredEntities />
      </Grid>
    </Grid>
  );
}
