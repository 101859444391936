import React, { useState, useRef } from 'react';
import { FieldValidation } from '@rjsf/utils';
import isDeepEqual from 'fast-deep-equal/react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Select, SelectItem, SelectedItems } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  has, mapValues, pick, uniq,
} from 'lodash';
import { useAsync } from 'react-use';
import { AWSCredentialsAPI, AWSProfile, awsCredentialsApiRef } from '@eng-portal/czi-extensions-react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

type Credentials = {
  accessKeyId: string | undefined,
  secretAccessKey: string | undefined,
  sessionToken: string | undefined,
  expiration: string | undefined
};
type AmazonProfilePicker = {
  credential: Credentials
  awsAccount: {
    name: string
    ID: string
  }
};

type PinnedAWSProfiles = {
  // key is the AWS account alias, value is the list of allowed AWS role names
  [key: string]: string[]
};

function createAccountToProfileMapping(profiles: AWSProfile[]): Record<string, AWSProfile[]> {
  const mapping: Record<string, AWSProfile[]> = {};
  profiles.forEach((profile) => {
    const accountAlias = profile.aws_account.alias;
    if (mapping[accountAlias]) {
      mapping[accountAlias].push(profile);
    } else {
      mapping[accountAlias] = [profile];
    }
  });
  return mapping;
}

type AWSState = {
  accountToProfileMapping: Record<string, AWSProfile[]>
  accountItems: { label: string, value: string }[]
  placeholderAcc: string
  placeholderRole: string
};

async function getAWSState(awsCredentialsApi: AWSCredentialsAPI, pinnedAWSProfiles: PinnedAWSProfiles = {}): Promise<AWSState | undefined> {
  const profiles = await awsCredentialsApi.getProfiles();
  let accountToProfileMapping = createAccountToProfileMapping(profiles);

  const pinnedAWSAccounts = Object.keys(pinnedAWSProfiles);
  if (pinnedAWSAccounts.length > 0) {
    const filteredMapping = pick(accountToProfileMapping, pinnedAWSAccounts);
    accountToProfileMapping = mapValues(
      filteredMapping,
      (profilesInAlias, alias) => profilesInAlias.filter((p) => pinnedAWSProfiles[alias].includes(p.role_name)),
    );
  }
  return {
    accountToProfileMapping,
    accountItems: Object.keys(accountToProfileMapping)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
      .map((p) => ({ label: p, value: p })),
    placeholderAcc: 'Select the account',
    placeholderRole: 'Select the role',
  };
}

async function getSTSToken(
  awsCredentialsApi: AWSCredentialsAPI,
  accountAlias: string,
  roleName: string,
): Promise<Credentials | undefined> {
  const STSCredential = await awsCredentialsApi.getSTSCredential(accountAlias, [roleName]);
  if (STSCredential) {
    return {
      accessKeyId: STSCredential.AccessKeyId,
      secretAccessKey: STSCredential.SecretAccessKey,
      sessionToken: STSCredential.SessionToken,
      expiration: STSCredential.Expiration?.toString(),
    };
  }
  throw new Error(`Cannot fetch STS token for account=${accountAlias} and role=${roleName}`);
}

function getRoleList(accountAlias: string, accountToProfileMapping: Record<string, AWSProfile[]>): SelectItem[] {
  if (!has(accountToProfileMapping, accountAlias)) {
    return [{ label: 'Loading...', value: 'loading' }];
  }

  const uniqueRoles = uniq(accountToProfileMapping[accountAlias].map((prof) => prof.role_name));
  return uniqueRoles.map((role) => ({ label: role, value: role }));
}

/**
 * The underlying component that is rendered in the form for the `AmazonProfilePicker`
 * field extension.
 *
 * @public
 */
export function AmazonProfilePickerExtension({
  onChange,
  rawErrors,
  required,
  formData,
  uiSchema,
}: FieldExtensionComponentProps<AmazonProfilePicker>): JSX.Element {
  const awsCredentialsApi = useApi(awsCredentialsApiRef);
  const [awsState, setAwsState] = useState<AWSState>({
    accountToProfileMapping: {},
    accountItems: [],
    placeholderAcc: 'Loading...',
    placeholderRole: 'Loading...',
  });
  const [accountAlias, setAccountAlias] = useState('');
  const [role, setRole] = useState('');
  const [roleItems, setRoleItems] = useState<SelectItem[]>([]);

  const uiSchemaRef = useRef(uiSchema);
  if (!isDeepEqual(uiSchemaRef.current, uiSchema)) {
    uiSchemaRef.current = uiSchema;
  }

  const title = uiSchemaRef.current['ui:options']?.title as string ?? 'Pick an AWS Account and Role';

  useAsync(async (): Promise<void> => {
    const pinnedAWSProfiles = (uiSchemaRef.current['ui:options']?.pinnedAWSProfiles ?? {}) as PinnedAWSProfiles;
    const state = await getAWSState(awsCredentialsApi, pinnedAWSProfiles);
    if (state) {
      setAwsState(state);
    }
  });

  const handleAccountSelected = (selection: SelectedItems): void => {
    const acctAlias = selection as string;
    setAccountAlias(acctAlias);
    setRole('');
    setRoleItems(getRoleList(acctAlias, awsState.accountToProfileMapping));
  };

  const handleRoleSelected = async (selection: SelectedItems): Promise<void> => {
    const roleName = selection as string;
    setRole(roleName);
    const credential = await getSTSToken(awsCredentialsApi, accountAlias, roleName);
    if (!credential) {
      throw new Error('Failed to fetch STS credentials');
    }

    onChange({
      credential,
      awsAccount: {
        name: accountAlias,
        ID: awsState.accountToProfileMapping[accountAlias][0].aws_account.id,
      },
    });
  };

  const roleSelectDisabled = !accountAlias || accountAlias.length === 0;
  const roleSelectPlaceholder = roleSelectDisabled ? 'Select the account first' : awsState.placeholderRole;
  return (
    <>
      <b>{title}</b>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0 && !formData}
      >
        <FormHelperText>Please Enable Pop-up for this extension</FormHelperText>
        <Select
          placeholder={awsState.placeholderAcc}
          label="AWS Account"
          selected={accountAlias}
          items={awsState.accountItems}
          onChange={handleAccountSelected}
        />
        <Select
          placeholder={roleSelectPlaceholder}
          label="Role"
          disabled={roleSelectDisabled}
          selected={role}
          items={roleItems}
          onChange={handleRoleSelected}
        />
      </FormControl>
    </>
  );
}

export const AmazonProfilePickerValidation = (
  value: AmazonProfilePicker,
  validation: FieldValidation,
): void => {
  const properties = [
    'credential.accessKeyId',
    'credential.secretAccessKey',
    'credential.sessionToken',
    'credential.expiration',
    'awsAccount.name',
    'awsAccount.ID',
  ];
  properties.forEach((property) => {
    let valid = true;
    if (Array.isArray(value)) {
      // handle the case in create-happy-env where we use this field extension as a nested object in an array
      // (it doesn't make sense but for some reason this gets the whole array as the `value` parameter)
      valid = value.every((nestedObjValues) => Object.values(nestedObjValues).some((v) => has(v, property)));
    } else {
      valid = has(value, property);
    }
    if (!valid) {
      validation.addError(
        `Invalid STS credentials: ${property} not found in value ${JSON.stringify(value)}`,
      );
    }
  });
};
