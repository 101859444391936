import { UserEntity, parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import {
  ConfigApi, configApiRef, identityApiRef, useApi, useRouteRef,
} from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import {
  Button, Grid, Typography,
} from '@material-ui/core';
import { Box } from '@mui/material';
import { AssumeUserPayload } from '@eng-portal/czi-extensions-common';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

function getAssumeUserEndpoint(config: ConfigApi): string {
  return `${config.getOptionalString('backend.baseUrl') || ''}/api/admin/assumedUser`;
}

export function AssumedUserMenuItem(): JSX.Element | null {
  const identityApi = useApi(identityApiRef);
  const entityRoute = useRouteRef(entityRouteRef);
  const config = useApi(configApiRef);

  const [assumedUser, setAssumedUser] = useState('');

  useAsync(async (): Promise<void> => {
    const assumedUserResp = await fetch(`${getAssumeUserEndpoint(config)}`, {
      headers: {
        Authorization: `Bearer ${(await identityApi.getCredentials()).token}`,
      },
    });
    const respJson = await assumedUserResp.json();
    setAssumedUser(respJson.assumedUser);
  });

  if (!assumedUser) {
    return null;
  }

  const assumedUserRoute = `${entityRoute(parseEntityRef(assumedUser))}`;
  return (
    <Grid style={{ background: 'red' }} container direction="row" justifyContent="center">
      <Grid item>
        <Typography>You have assumed user:</Typography>
        <Link to={assumedUserRoute}>
          <Typography><Box fontWeight="fontWeightMedium" display="inline">{assumedUser}</Box></Typography>
        </Link>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={async (): Promise<void> => {
            await fetch(`${getAssumeUserEndpoint(config)}`, {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${(await identityApi.getCredentials()).token}`,
              },
            });
            window.location.reload();
          }}
        >
          Stop Assuming
        </Button>
      </Grid>
    </Grid>
  );
}

export function SetAssumedUserButton(props: { user: UserEntity }): JSX.Element {
  const identityApi = useApi(identityApiRef);
  const config = useApi(configApiRef);
  const { user } = props;
  return (
    <Button
      variant="contained"
      onClick={async (): Promise<void> => {
        await fetch(getAssumeUserEndpoint(config), {
          method: 'POST',
          body: JSON.stringify({
            assumeUser: stringifyEntityRef({ kind: 'user', name: user.metadata.name }),
          } as AssumeUserPayload),
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${(await identityApi.getCredentials()).token}`,
          },
        });
        window.location.reload();
      }}
    >
      Assume User Role
    </Button>
  );
}
