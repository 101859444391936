import {
  CostExplorerClient, Dimension, Expression, Granularity,
  GetDimensionValuesCommand, GetDimensionValuesCommandOutput,
  GetSavingsPlansCoverageCommand, GetSavingsPlansCoverageCommandInput, GetSavingsPlansCoverageCommandOutput,
} from '@aws-sdk/client-cost-explorer';
import { createApiRef } from '@backstage/core-plugin-api';
import { AWSCredentialsAPI } from '@eng-portal/czi-extensions-react';

export type FilterOption = {
  linkedAccounts: string[];
  regions: string[];
  // TODO: added service and instance type
  granularity: Granularity;
};

export const awsCostExplorerApiRef = createApiRef<AWSCostExplorerAPI>({ id: 'aws-cost-explorer.api' });

export interface AWSCostExplorerAPI {
  getSavingsPlansCoverageRequest(Start: string, End: string, Option: FilterOption): Promise<GetSavingsPlansCoverageCommandOutput>
  getLinkedAccounts(Start: string, End: string): Promise<LinkedAccount[]>
  getRegions(Start: string, End: string): Promise<string[]>
}

export class AWSCostExplorerClient implements AWSCostExplorerAPI {
  constructor(
    private readonly awsCredentialsApi: AWSCredentialsAPI,
    private readonly region: string,
  ) {}

  async getClient(): Promise<CostExplorerClient> {
    const credentials = await this.awsCredentialsApi.getSTSCredential('czi-id', ['okta-czi-admin']);
    return new CostExplorerClient({
      region: this.region,
      credentials: {
        accessKeyId: credentials?.AccessKeyId!,
        secretAccessKey: credentials?.SecretAccessKey!,
        sessionToken: credentials?.SessionToken,
      },
    });
  }

  async getDimensionValuesRequest(Start: string, End: string, dimension: Dimension): Promise<GetDimensionValuesCommandOutput> {
    const input = {
      TimePeriod: {
        Start,
        End,
      },
      Dimension: dimension,
    };
    const command = new GetDimensionValuesCommand(input);
    const client = await this.getClient();
    const response = await client.send(command);
    return response;
  }

  async getSavingsPlansCoverageRequest(Start: string, End: string, Option: FilterOption): Promise<GetSavingsPlansCoverageCommandOutput> {
    const regionExpr: Expression | null = Option.regions.length > 0 ? {
      Dimensions: {
        Key: Dimension.REGION,
        Values: Option.regions,
      },
    } : null;
    const accountExpr: Expression | null = Option.linkedAccounts.length > 0 ? {
      Dimensions: {
        Key: Dimension.LINKED_ACCOUNT,
        Values: Option.linkedAccounts,
      },
    } : null;
    let filterExpr: Expression | null = null;
    if (accountExpr && regionExpr) {
      filterExpr = {
        And: [
          accountExpr, regionExpr,
        ],
      };
    } else if (accountExpr) {
      filterExpr = accountExpr;
    } else if (regionExpr) {
      filterExpr = regionExpr;
    }
    const input: GetSavingsPlansCoverageCommandInput = {
      TimePeriod: {
        Start,
        End,
      },
      Granularity: Option.granularity,
    };
    if (filterExpr) {
      input.Filter = filterExpr;
    }
    const command = new GetSavingsPlansCoverageCommand(input);
    const client = await this.getClient();
    try {
      const response = await client.send(command);
      return response;
    } catch (e) {
      throw new Error('Data is unavailable for this setting');
    }
  }

  async getLinkedAccounts(Start: string, End: string): Promise<LinkedAccount[]> {
    const resp = await this.getDimensionValuesRequest(Start, End, Dimension.LINKED_ACCOUNT);
    const accounts: LinkedAccount[] = (resp.DimensionValues || [])
      .map((val) => ({
        linkedAccountID: val.Value || '',
        linkedAccountName: val.Attributes?.description || '',
      }))
      .filter((val) => val && val.linkedAccountID && val.linkedAccountName);
    return accounts;
  }

  async getRegions(Start: string, End: string): Promise<string[]> {
    const resp = await this.getDimensionValuesRequest(Start, End, Dimension.REGION);
    const regions: string[] = (resp.DimensionValues || [])
      .map((val) => (val.Value || ''))
      .filter((val) => val);
    return regions;
  }
}

export type LinkedAccount = {
  linkedAccountID: string;
  linkedAccountName: string;
};
