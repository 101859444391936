import { RetryableOptions, retryable } from '@eng-portal/czi-extensions-common';
import { get } from 'lodash';

type GetOptions = {
  fetchFn: () => Promise<any>
  ttlSeconds: number
  retryOptions?: RetryableOptions
  validateFn?: (item: any) => boolean
  storage?: Storage
};

type CachedEntry = {
  item: any
  expiration: Date
};

const managedKeyPrefix = '/LocalStorageCache/';

export class LocalStorageCache {
  static async get<T = any>(key: string, opts: GetOptions): Promise<T> {
    const retryOptions = get(opts, 'retryOptions', {});
    const validateFn = get(opts, 'validateFn', () => true);
    const storage = get(opts, 'storage', window.localStorage);
    const managedKey = `${managedKeyPrefix}${key}`;

    const cachedItem = storage.getItem(managedKey);
    if (cachedItem) {
      const cachedItemJson: CachedEntry = JSON.parse(cachedItem);
      const expiration = get(cachedItemJson, 'expiration');
      const item = get(cachedItemJson, 'item');
      const validItem = item && validateFn(item);
      if (validItem && expiration && new Date(expiration) > new Date(Date.now())) {
        return item;
      }
    }

    const item = await retryable(opts.fetchFn, retryOptions);
    if (!item) {
      throw new Error(`Failed to retrieve value for key: ${key}`);
    }

    const expiration = new Date(Date.now() + opts.ttlSeconds * 1000);
    const entry: CachedEntry = { item, expiration };
    const itemJson = JSON.stringify(entry);

    storage.setItem(managedKey, itemJson);
    return item;
  }

  static clear(opts?: { storage: Storage }): void {
    const storage = get(opts, 'storage', window.localStorage);
    Object.keys(storage).forEach((key) => {
      if (key.startsWith(managedKeyPrefix)) {
        storage.removeItem(key);
      }
    });
  }
}
