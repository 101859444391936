import {
  configApiRef, createApiFactory, createPlugin, createRoutableExtension,
} from '@backstage/core-plugin-api';
import { oktaTokenApiRef } from '@eng-portal/czi-extensions-react';
import { rootRouteRef } from './routes';
import { PrivilegeEscalationClient, privilegeEscalationApiRef } from './apis/privilegeEscalationApi';

export const privilegeEscalationPlugin = createPlugin({
  id: 'privilege-escalation',
  apis: [
    createApiFactory({
      api: privilegeEscalationApiRef,
      deps: { configApi: configApiRef, oktaTokenApi: oktaTokenApiRef },
      factory: ({ configApi, oktaTokenApi }) => new PrivilegeEscalationClient(oktaTokenApi, configApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const PrivilegeEscalationPage = privilegeEscalationPlugin.provide(
  createRoutableExtension({
    name: 'PrivilegeEscalationPage',
    component: () => import('./components/Router').then((m) => m.Router),
    mountPoint: rootRouteRef,
  }),
);
