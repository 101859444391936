import React, { useState } from 'react';
import { InfoCard, Table, TableColumn } from '@backstage/core-components';
import { UserEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';
import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { includesAdminGroup } from '@eng-portal/czi-extensions-common';
import { SetAssumedUserButton } from '../Root/AssumedUser';

export function AdminActionsCard(): JSX.Element | null {
  const { entity: user } = useEntity<UserEntity>();
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const [loggedInUserGroups, setLoggedInUserGroups] = useState<string[]>([]);

  useAsync(async (): Promise<void> => {
    const { ownershipEntityRefs } = await identityApi.getBackstageIdentity();
    setLoggedInUserGroups(ownershipEntityRefs);
  });

  if (!includesAdminGroup(loggedInUserGroups, { config })) {
    return null;
  }

  const columns: TableColumn[] = [
    {
      render: (item: any) => item.button,
      width: '40%',
    },
    {
      field: 'description',
    },
  ];

  const data = [
    {
      id: 1,
      button: <SetAssumedUserButton user={user} />,
      description: 'Replaces your groups with the groups this user is a part of to allow you to test permissions policies.',
    },
  ];

  return (
    <InfoCard title="Admin Actions">
      <Table
        options={{
          paging: false, search: false, header: false, toolbar: false,
        }}
        data={data}
        columns={columns}
      />
    </InfoCard>
  );
}
