import React from 'react';
import { useEntity, EntityRefLinks, getEntityRelations } from '@backstage/plugin-catalog-react';
import {
  Avatar, EmailIcon, InfoCard, Link, StructuredMetadataTable,
} from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import {
  Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import GroupIcon from '@material-ui/icons/Group';
import { OktaUserEntity } from '@eng-portal/czi-extensions-common';
import { RELATION_MEMBER_OF } from '@backstage/catalog-model';
import { omit } from 'lodash';
import { CardTitle } from './CardTitle';

export function OktaUserProfileCard(): JSX.Element {
  const { entity: user } = useEntity<OktaUserEntity>();
  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }

  const {
    metadata: { description },
    spec: { profile },
  } = user;
  const { displayName, email } = profile;
  const emailHref = email ? `mailto:${email}` : undefined;
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Group',
  });
  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      subheader={description}
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar
            displayName={displayName}
            picture={profile?.picture}
          />
        </Grid>
        <Grid item md={10} xl={11}>
          <List>
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={emailHref ?? ''}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Member of">
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                <EntityRefLinks
                  entityRefs={memberOfRelations}
                  defaultKind="Group"
                />
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Hierachy">
                  <AccountTreeIcon />
                </Tooltip>
              </ListItemIcon>
              <StructuredMetadataTable metadata={omit(profile, ['displayName', 'email'])} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </InfoCard>

  );
}
